import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from '../styles';

const TermsAndConditions: React.FC = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <div className={classes.root}>
        <Typography className={classes.title} variant="h1">
          Solvana&trade; Terms of Service
        </Typography>
        <h3>1. Terms</h3>
        <p>
          By accessing the website at <a href="https://www.solvana.io">www.solvana.io</a>, you are agreeing to be bound
          by these terms of service, all applicable laws, and regulations, and agree that you are responsible for
          compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited
          from using or accessing this site. The materials contained in this website are protected by applicable
          copyright and trademark law.
        </p>
        <h3>2. Use License</h3>
        <ol type="a">
          <li>
            Permission is granted to temporarily download one copy of the materials (information or software) on
            Solvana&apos;s website for personal, non-commercial transitory viewing only. This is the grant of a license,
            not a transfer of title, and under this license, you may not:
            <ol type="i">
              <li>modify or copy the materials;</li>
              <li>
                use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
              </li>
              <li>attempt to decompile or reverse engineer any software contained on Solvana&apos;s website;</li>
              <li>remove any copyright or other proprietary notations from the materials; or</li>
              <li>transfer the materials to another person or &quot;mirror&quot; the materials on any other server.</li>
            </ol>
          </li>
          <li>
            This license shall automatically terminate if you violate any of these restrictions and may be terminated by
            Solvana at any time. Upon terminating your viewing of these materials or upon the termination of this
            license, you must destroy any downloaded materials in your possession whether in electronic or printed
            format.
          </li>
        </ol>
        <h3>3. Disclaimer</h3>
        <ol type="a">
          <li>
            The materials on Solvana&apos;s website are provided on an &apos;as is&apos; basis. Solvana makes no
            warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without
            limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or
            non-infringement of intellectual property or other violation of rights.
          </li>
          <li>
            Further, Solvana does not warrant or make any representations concerning the accuracy, likely results, or
            reliability of the use of the materials on its website or otherwise relating to such materials or on any
            sites linked to this site.
          </li>
        </ol>
        <h3>4. Limitations</h3>
        <p>
          In no event shall Solvana or its suppliers be liable for any damages (including, without limitation, damages
          for loss of data or profit, or due to business interruption) arising out of the use or inability to use the
          materials on Solvana&apos;s website, even if Solvana or a Solvana authorized representative has been notified
          orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on
          implied warranties, or limitations of liability for consequential or incidental damages, these limitations may
          not apply to you.
        </p>
        <h3>5. Accuracy of materials</h3>
        <p>
          The materials appearing on Solvana&apos;s website could include technical, typographical, or photographic
          errors. Solvana does not warrant that any of the materials on its website are accurate, complete or current.
          Solvana may make changes to the materials contained on its website at any time without notice. However Solvana
          does not make any commitment to update the materials.
        </p>
        <h3>6. Links</h3>
        <p>
          Solvana has not reviewed all of the sites linked to its website and is not responsible for the contents of any
          such linked site. The inclusion of any link does not imply endorsement by Solvana of the site. Use of any such
          linked website is at the user&apos;s own risk.
        </p>
        <h3>7. Modifications</h3>
        <p>
          Solvana may revise these terms of service for its website at any time without notice. By using this website
          you are agreeing to be bound by the then current version of these terms of service.
        </p>
        <h3>8. Governing Law</h3>
        <p>
          These terms and conditions are governed by and construed in accordance with the laws of New Jersey and you
          irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
        </p>
        Collapse 3:47 Post Cookie Policy
        <h2>Cookie Policy</h2>
        <p>
          We use cookies to help improve your experience of <a href="https://solvana.io">www.solvana.io</a>. This cookie
          policy is part of Solvana&apos;s privacy policy and covers the use of cookies between your device and our
          site. We also provide basic information on third-party services we may use, who may also use cookies as part
          of their service, though they are not covered by our policy.
        </p>
        <p>
          If you don’t wish to accept cookies from us, you should instruct your browser to refuse cookies from{' '}
          <a href="https://solvana.io">https://www.solvana.io</a>, with the understanding that we may be unable to
          provide you with some of your desired content and services.
        </p>
        <h3>What is a cookie?</h3>
        <p>
          A cookie is a small piece of data that a website stores on your device when you visit, typically containing
          information about the website itself, a unique identifier that allows the site to recognize your web browser
          when you return, additional data that serves the purpose of the cookie, and the lifespan of the cookie itself.
        </p>
        <p>
          Cookies are used to enable certain features (eg. logging in), to track site usage (eg. analytics), to store
          your user settings (eg. timezone, notification preferences), and to personalize your content (eg. advertising,
          language).
        </p>
        <p>
          Cookies set by the website you are visiting are normally referred to as “first-party cookies”, and typically
          only track your activity on that particular site. Cookies set by other sites and companies (ie. third parties)
          are called “third-party cookies”, and can be used to track you on other websites that use the same third-party
          service.
        </p>
        <h3>Types of cookies and how we use them</h3>
        <h4>Essential cookies</h4>
        <p>
          Essential cookies are crucial to your experience of a website, enabling core features like user logins,
          account management, shopping carts, and payment processing. We use essential cookies to enable certain
          functions on our website.
        </p>
        <h4>Performance cookies</h4>
        <p>
          Performance cookies are used in the tracking of how you use a website during your visit, without collecting
          personal information about you. Typically, this information is anonymous and aggregated with information
          tracked across all site users, to help companies understand visitor usage patterns, identify and diagnose
          problems or errors their users may encounter, and make better strategic decisions in improving their
          audience’s overall website experience. These cookies may be set by the website you’re visiting (first-party)
          or by third-party services. We use performance cookies on our site.
        </p>
        <h4>Functionality cookies</h4>
        <p>
          Functionality cookies are used in collecting information about your device and any settings you may configure
          on the website you’re visiting (like language and time zone settings). With this information, websites can
          provide you with customized, enhanced, or optimized content and services. These cookies may be set by the
          website you’re visiting (first-party) or by third-party service. We use functionality cookies for selected
          features on our site.
        </p>
        <h4>Targeting/advertising cookies</h4>
        <p>
          Targeting/advertising cookies are used in determining what promotional content is more relevant and
          appropriate to you and your interests. Websites may use them to deliver targeted advertising or to limit the
          number of times you see an advertisement. This helps companies improve the effectiveness of their campaigns
          and the quality of content presented to you. These cookies may be set by the website you’re visiting
          (first-party) or by third-party services. Targeting/advertising cookies set by third-parties may be used to
          track you on other websites that use the same third-party service. We use targeting/advertising cookies on our
          site.
        </p>
        <h3>Third-party cookies on our site</h3>
        <p>
          We may employ third-party companies and individuals on our websites—for example, analytics providers and
          content partners. We grant these third parties access to selected information to perform specific tasks on our
          behalf. They may also set third-party cookies in order to deliver the services they are providing. Third-party
          cookies can be used to track you on other websites that use the same third-party service. As we have no
          control over third-party cookies, they are not covered by Solvana&apos;s cookie policy.
        </p>
        <h4>Our third-party privacy promise</h4>
        <p>
          We review the privacy policies of all our third-party providers before enlisting their services to ensure
          their practices align with ours. We will never knowingly include third-party services that compromise or
          violate the privacy of our users.
        </p>
        <h3>How you can control or opt-out of cookies</h3>
        <p>
          If you do not wish to accept cookies from us, you can instruct your browser to refuse cookies from our
          website. Most browsers are configured to accept cookies by default, but you can update these settings to
          either refuse cookies altogether or to notify you when a website is trying to set or update a cookie.
        </p>
        <p>
          If you browse websites from multiple devices, you may need to update your settings on each individual device.
        </p>
        <p>
          Although some cookies can be blocked with little impact on your experience of a website, blocking all cookies
          may mean you are unable to access certain features and content across the sites you visit.
        </p>
      </div>
    </Container>
  );
};

export default TermsAndConditions;
