import React from 'react';
import Layout from '../containers/Layout';
import TermsAndConditions from '../containers/Legal/TermsAndConditions';

const TermsAndConditionsPage: React.FC = () => (
  <Layout title="Solvana | Terms of Service">
    <TermsAndConditions />
  </Layout>
);

export default TermsAndConditionsPage;
